import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination as BootstrapPagination } from "react-bootstrap";

const getPage = ({ search }) => {
  const pageReg = /\?page=(\d+)/;
  const matchedPage = search.match(pageReg);

  if (!matchedPage) return 0;

  const [_, result] = matchedPage;

  return parseInt(result);
};

const getPagination = (currentPage, totalPage, pageSize) => {
  const totalPagination = Array.from(
    {
      length: totalPage,
    },
    (_, index) => index + 1
  );

  const firstPage = currentPage - (currentPage % pageSize);
  const lastPage = currentPage - (currentPage % pageSize) + pageSize;

  return totalPagination.slice(firstPage, lastPage);
};

const CustomPagination = ({
  currentPage,
  totalPage,
  setPage,
  pageSize = 10,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setPage(getPage(location));
  }, [location, setPage]);

  const paginationInfo = useMemo(() => {
    return getPagination(currentPage, totalPage, pageSize);
  }, [currentPage, totalPage, pageSize]);

  const handlePageMove = (page) => {
    if (page <= -1 || page >= totalPage || page === getPage(location)) return;

    navigate(`?page=${page}`);
  };

  const mappedPagination = paginationInfo.map((num) => (
    <BootstrapPagination.Item
      key={num}
      className={num === currentPage + 1 ? "page on" : "page"}
      onClick={() => handlePageMove(num - 1)}
    >
      {num}
    </BootstrapPagination.Item>
  ));

  return (
    <div className="d-flex justify-content-center">
      {totalPage ? (
        <BootstrapPagination className="align-items-center">
          <BootstrapPagination.First onClick={() => handlePageMove(0)} />
          <BootstrapPagination.Prev
            onClick={() => handlePageMove(currentPage - 1)}
          />
          {mappedPagination}
          <BootstrapPagination.Next
            onClick={() => handlePageMove(currentPage + 1)}
          />
          <BootstrapPagination.Last
            onClick={() => handlePageMove(totalPage - 1)}
          />
        </BootstrapPagination>
      ) : null}
    </div>
  );
};

export default CustomPagination;
