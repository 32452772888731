import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import "./Login_v1.css";
import axios from "axios";
import { getApiUrl } from "../../function/common";

import { PrintConsole, sleep } from "../../function/common";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useAuthContext from "../../hooks/useAuthContext";

export default function Login() {
  const navigate = useNavigate();
  const { setContextLogin } = useAuthContext();

  const [account, setAccount] = useState({
    Id: "flex",
    Password: "",
    clientId: "10001",
  });

  const onChangeAccount = (e) => {
    //console.log(e.target.value);
    setAccount({
      ...account,
      [e.target.id]: e.target.value,
    });
  };

  function today() {
    localStorage.setItem("TodayPopup", "false");
  }

  function loginBtn() {
    return new Promise(async function (resolve, reject) {
      let obj = {
        loginId: "flex",
        password: document.getElementById("Password").value,
      };
      // flex!12@
      let json = JSON.stringify(obj);
      const response = await axios
        .post(
          getApiUrl("v1/auth/login"),
          json,
          (axios.defaults.headers.post["Content-Type"] = "application/json;")
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
        )
        .then(function (response) {
          if (response.status === 200) {
            localStorage.setItem(
              "accessToken",
              response.data.loginToken.accessToken
            );
            setContextLogin();
            navigate("/adlist");
          }
        })
        .catch(function (error) {
          PrintConsole(error);
        });
    });
  }

  useEffect(() => {}, []);

  return (
    <>
      <div className="demo">
        <div className="TLoginPage">
          <div className="container position-absolute start-50 top-50 translate-middle w-100">
            <div className="Inner m-auto p-2">
              <div className="LogoImg text-center">
                <img
                  src="https://www.flexdaycdn.net/public/images/ticket/inje_logo2.png"
                  alt="FLEX"
                />
              </div>

              <div className="LoginBox">
                <div className="InputboxWrap">
                  <p className="tit">암호를 입력해주세요.</p>
                  <div className="AppleRegister-Inputbox">
                    <input
                      type="password"
                      id="Password"
                      className="form-control InputType"
                      placeholder="암호를 입력해주세요."
                    />
                  </div>
                  <div className="AppleRegister-Confirm pt-4">
                    <button
                      onClick={() => {
                        loginBtn();
                      }}
                      type="button"
                      className="btn btn-light"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
