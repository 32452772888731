import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { forwardRef } from "react";
import { Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const DropdownCustomToggle = forwardRef(({ children, onClick }, ref) => {
  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        paddingLeft: "2rem",
        paddingBottom: "1rem",
        cursor: "pointer",
      }}
    >
      {children}
    </div>
  );
});

const AdListItem = ({
  advertisementId: id,
  title,
  startDate,
  endDate,
  createdAt,
  isFirst,
  onClickEdit = () => {},
  onClickDelete = () => {},
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: "1.6rem",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.8rem 0.4rem",
        borderTop: isFirst ? "1px solid var(--ColorTypeD)" : "none",
        borderBottom: "1px solid var(--ColorTypeD)",
        fontSize: "14px",
        position: "relative",
        paddingRight: "2.4rem",
      }}
    >
      <div style={{ flexShrink: 0, fontWeight: 700 }}>{id}</div>
      <div style={{ flex: 1 }}>
        <Row
          style={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          <Link to={"/adview/" + id} className="px-0">
            {title}
          </Link>
        </Row>
        <Row>
          (&nbsp;{moment(startDate).format("YY-MM-DD")}&nbsp;~&nbsp;
          {moment(endDate).format("YY-MM-DD")}&nbsp;)
        </Row>
      </div>
      <div>
        생성일 :
        <br />
        {createdAt}
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 14,
        }}
      >
        <Dropdown>
          <Dropdown.Toggle as={DropdownCustomToggle}>
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={onClickEdit}>수정하기</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={onClickDelete}>삭제하기</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default AdListItem;
