import { createBrowserRouter } from "react-router-dom";
import AdList from "../pages/UserMain/AdList";
import Error from "../pages/Error";
import AdCreate from "../pages/UserMain/AdCreate";
import AdModify from "../pages/UserMain/AdModify";
import AdView from "../pages/UserMain/AdView";
import NotFound from "../pages/NotFound";
import UserLogin from "../pages/UserMain/UserLogin";

const AppRouter = (loggedIn) => {
  if (loggedIn) {
    return createBrowserRouter([
      {
        path: "/",
        errorElement: <Error />,
        children: [
          {
            path: "",
            element: <AdList />,
          },
          {
            path: "adlist",
            element: <AdList />,
          },
          {
            path: "adcreate",
            element: <AdCreate />,
          },
          {
            path: "admodify",
            element: <AdModify />,
            children: [
              {
                path: ":number",
                element: <AdModify />,
              },
            ],
          },
          {
            path: "adview",
            element: <AdView />,
            children: [
              {
                path: ":number",
                element: <AdView />,
              },
            ],
          },
          {
            path: "error",
            element: <Error />,
          },
          {
            path: "404",
            element: <NotFound />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ]);
  } else {
    return createBrowserRouter([
      {
        path: "/",
        children: [
          {
            path: "",
            element: <UserLogin />,
          },
          {
            path: "login",
            element: <UserLogin />,
          },
          {
            path: "error",
            element: <Error />,
          },
          {
            path: "404",
            element: <NotFound />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ]);
  }
};

export default AppRouter;
