import { useNavigate } from "react-router-dom";
import { getApiUrl } from "./../../function/common";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Col, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

const AdView = () => {
  const navigate = useNavigate();
  const { number } = useParams();
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState({
    title: "",
    titleBannerUrl: null,
    startDate: "",
    endDate: "",
    link: "",
  });

  const getBanner = () => {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("v1/advertisements/" + number),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          setBanner(response.data);
        });
      if (response) {
        return resolve(response);
      }
    });
  };

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //함수 호출
    getBanner();

    //데이터 로딩 완료
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  if (loading) return <></>;

  return (
    <>
      <div className="TAddTicketPage">
        <div className="container-fluid g-0">
          <nav className="sub-navbar d-flex">
            <p className="navbar-title">광고 등록</p>
          </nav>

          <div className="AddPrdBox py-4-5 px-4-5">
            <div className="AddPrd-Form">
              <div className="AddPrd-Formitem pb-5">
                <p className="addprd-tit">1. 광고 제목</p>
                <div className="addprd-inputbox Input-placeholderbox px-3">
                  <p>{banner.title ?? "-"}</p>
                </div>
              </div>
              <div className="AddPrd-Formitem pb-5">
                <p className="addprd-tit">2. 링크 URL</p>
                <div className="addprd-inputbox Input-placeholderbox px-3">
                  <p>
                    <a
                      href={banner.link ?? ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {banner.link ?? "-"}
                    </a>
                  </p>
                </div>
              </div>
              <div className="AddPrd-Formitem pb-5">
                <p className="addprd-tit">2. 배너 이미지</p>
                <div
                  className="addprd-inputbox px-3"
                  style={{
                    maxHeight: 200,
                    height: "100%",
                    aspectRatio: 800 / 365,
                    overflow: "hidden",
                  }}
                >
                  <Image
                    className="img-fluid"
                    src={
                      Boolean(banner.titleBannerUrl)
                        ? banner.titleBannerUrl
                        : null
                    }
                  />
                </div>
              </div>
              <div className="AddPrd-Formitem pb-5">
                <p className="addprd-tit">3. 광고 게시 기간</p>

                <Row>
                  <Col>
                    <span>
                      {`${banner.startDate ?? "-"} ~ ${banner.endDate ?? "-"}`}
                    </span>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="AddPrd-Formitem">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-primary me-md-2 createBtn"
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  목록
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdView;
