import { createContext, useContext, useState } from "react";

const AuthContext = createContext({
  loggedIn: false,
  setContextLogin: () => {},
  setContextLogout: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const setContextLogin = () =>
    setState((prev) => ({ ...prev, loggedIn: true }));

  const setContextLogout = () => {
    setState((prev) => ({ ...prev, loggedIn: false }));
    localStorage.clear();
    window.location.replace("/");
  };

  const initialState = {
    loggedIn: Boolean(localStorage.getItem("accessToken")) ?? false,
    setContextLogin,
    setContextLogout,
  };

  const [state, setState] = useState(initialState);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export default useAuthContext;
