import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./NotFound.css";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <div className="demo">
        <div className="T404Page">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">404</p>
              <button
                type="button"
                className="btn-clear btn-close"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/close5.png"
                />
              </button>
            </nav>

            <div className="FailureBox Inner">
              <div className="FailureBox-title my-5 text-center">
                <img
                  className="img-fluid mb-4"
                  src="https://www.flexdaycdn.net/public/images/static/store-register/cry-emoji.png"
                  alt=""
                />
                <p className="PageTit2 mb-4">페이지를 찾을 수 없어요 !</p>
                <p className="PageSub">
                  요청하신 페이지가 사라졌거나
                  <br />
                  잘못된 경로를 이용하셨습니다.
                </p>
              </div>
            </div>

            <div className="fixed-bottom px-4-5 pt-3 pb-4">
              <div className="CancelConfirm d-flex justify-content-between flex-wrap">
                <p className="PageSub text-center mb-4 w-100">
                  아래 버튼을 눌러 다시 시도해주세요. <br />
                  응답이 없을 경우, 앱을 종료하신 후 다시 실행해주세요.
                </p>
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn btn-default"
                >
                  이전화면으로 가기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
