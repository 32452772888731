import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

import "./App.css";

import { RouterProvider } from "react-router-dom";
import useAuthContext from "./hooks/useAuthContext";
import AppRouter from "./routes/AppRouter";

const App = () => {
  if (process.env.NODE_ENV !== "production") {
    console.log("domainType : " + localStorage.getItem("domainType"));
  }

  const { loggedIn } = useAuthContext();

  return <RouterProvider router={AppRouter(loggedIn)} />;
};

export default App;
