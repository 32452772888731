import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getApiUrl } from "./../../function/common";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import CustomPagination from "../../components/common/Pagination";
import AdListItem from "./components/AdListItem";
import useAuthContext from "../../hooks/useAuthContext";

export default function AdList() {
  const { setContextLogout } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }
  const [banner, setBanner] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageInfo, setPageInfo] = useState({
    totalElements: 0,
    totalPages: 0,
  });

  function getBanner() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("v1/advertisements"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          const { totalElements, totalPages } = response.data;
          setPageInfo({
            totalElements,
            totalPages,
          });
          setBanner(response.data);
        })
        .catch((err) => {
          const errMessage = err.response?.data?.message ?? "";
          const errHttpStatus = err.response?.data?.httpStatus ?? "";
          if (errHttpStatus === "UNAUTHORIZED") {
            alert(errMessage);
            return setContextLogout();
          }
          console.log(errMessage);
        });
      if (response) {
        return resolve(response);
      }
    });
  }

  function delBanner(id) {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .delete(
          getApiUrl("v1/advertisements/" + id),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            alert("배너 삭제 완료");
            getBanner();
          }
        });
      if (response) {
        return resolve(response);
      }
    });
  }

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //함수 호출
    getBanner();

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  if (loading) return <></>;

  return (
    <>
      <div className="demo">
        <Container className="mt-4-5">
          <Row className="mb-4 h-40px d-flex align-items-center">
            <Col>
              <p className="navbar-title" style={{ width: "fit-content" }}>
                광고 리스트
              </p>
            </Col>
            <Col>
              <div className="d-grid gap-2 d-md-flex justify-content-end">
                <button
                  className="btn btn-primary me-md-2"
                  type="button"
                  onClick={() => {
                    navigate("/adcreate");
                  }}
                  style={{
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                    width: "fit-content",
                    height: "auto",
                    borderRadius: "0.4rem",
                    padding: "0.4rem 1.6rem",
                    fontSize: "0.9rem",
                  }}
                >
                  새 광고 만들기
                </button>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            {banner && banner.content.length > 0 ? (
              <Col className="d-flex flex-column w-100 mb-3">
                {banner.content.map((row, index) => (
                  <AdListItem
                    key={row.advertisementId}
                    {...row}
                    isFirst={index === 0}
                    onClickEdit={() => {
                      navigate("/admodify/" + row.advertisementId);
                    }}
                    onClickDelete={() => {
                      delBanner(row.advertisementId);
                    }}
                  />
                ))}
              </Col>
            ) : (
              <div>등록된 광고가 없습니다.</div>
            )}
            <CustomPagination
              currentPage={currentPage}
              totalPage={pageInfo.totalPages}
              setPage={setCurrentPage}
            />
          </Row>
        </Container>
      </div>
    </>
  );
}
