import { useNavigate, useParams } from "react-router-dom";
import { AlertError, getApiUrl, onClickUpload } from "./../../function/common";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

const AdModify = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { number } = useParams();
  const [banner, setBanner] = useState({
    advertisementId: null,
    startDate: new Date(),
    endDate: new Date(),
    title: "",
    titleBannerUrl: "",
    link: "",
  });
  const fileUpload = useRef();

  const onChangeBannerData = (value, target) => {
    setBanner((prev) => ({ ...prev, [target]: value }));
  };

  const submit = async () => {
    const { title, startDate, endDate, titleBannerUrl, link } = banner;
    let obj = {
      title,
      startDate,
      endDate,
      link,
    };
    let json = JSON.stringify(obj);

    const formData = new FormData();
    formData.append("request", json);
    formData.append(
      "titleBanner",
      titleBannerUrl.file ? titleBannerUrl.file : titleBannerUrl
    );

    axios
      .put(getApiUrl("v1/advertisements/" + number), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then(function (response) {
        alert("광고가 수정되었습니다.");
        navigate("/adlist");
      })
      .catch((error) => {
        AlertError(error);
      });
  };

  const getBanner = () => {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("v1/advertisements/" + number),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          const { startDate, endDate } = response.data;
          setBanner({
            ...response.data,
            startDate: startDate ? new Date(startDate) : new Date(),
            endDate: endDate ? new Date(endDate) : new Date(),
          });
        });
      if (response) {
        return resolve(response);
      }
    });
  };

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //함수 호출
    getBanner();

    //데이터 로딩 완료
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  if (loading) return <></>;

  return (
    <>
      <div className="TAddTicketPage">
        <div className="container g-0">
          <nav className="sub-navbar d-flex">
            <p className="navbar-title">광고 수정</p>
          </nav>

          <div className="AddPrdBox py-4-5 px-4-5">
            <div className="AddPrd-Form">
              <div className="AddPrd-Formitem pb-5">
                <p className="addprd-tit">1. 광고 제목</p>
                <div className="addprd-inputbox Input-placeholderbox px-3">
                  <input
                    type="text"
                    id="title"
                    className="form-control InputType title"
                    placeholder="간단한 광고 제목을 입력해주세요."
                    value={banner?.title ?? ""}
                    onChange={(e) =>
                      onChangeBannerData(e.target.value, "title")
                    }
                  />
                </div>
              </div>
              <div className="AddPrd-Formitem pb-5">
                <p className="addprd-tit">2. 링크 URL</p>
                <div className="addprd-inputbox Input-placeholderbox px-3">
                  <input
                    type="text"
                    id="link"
                    className="form-control InputType title"
                    placeholder="배너 클릭 시 이동할 url을 입력해주세요."
                    value={banner.link ?? ""}
                    onChange={(e) => onChangeBannerData(e.target.value, "link")}
                  />
                </div>
              </div>
              <div className="AddPrd-Formitem pb-5">
                <p
                  className="addprd-tit"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                  }}
                >
                  2. 배너 이미지
                  <span style={{ fontSize: "0.8rem", opacity: 0.6 }}>
                    &nbsp;(&nbsp;이미지는&nbsp;최대&nbsp;800px&nbsp;⨉&nbsp;365px의&nbsp;비율로
                    삽입됩니다.&nbsp;)
                  </span>
                </p>
                <div className="addprd-inputbox px-3">
                  <input
                    type="file"
                    ref={fileUpload}
                    onChange={(e) =>
                      onClickUpload(e, (value) =>
                        onChangeBannerData(value, "titleBannerUrl")
                      )
                    }
                  />
                </div>
                {Boolean(banner.titleBannerUrl) && (
                  <div
                    style={{
                      maxHeight: 200,
                      height: "100%",
                      aspectRatio: 800 / 365,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="px-3 mt-3"
                      src={
                        banner.titleBannerUrl.url
                          ? banner.titleBannerUrl.url
                          : banner.titleBannerUrl
                      }
                      alt="bannerTitleUrl"
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: "top center",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="AddPrd-Formitem pb-5">
                <p className="addprd-tit">3. 광고 게시 기간</p>

                <Row>
                  <Col>
                    <ReactDatePicker
                      className="text-center"
                      selected={banner.startDate}
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => onChangeBannerData(date, "startDate")}
                    />
                  </Col>
                  <Col>
                    <ReactDatePicker
                      className="text-center"
                      selected={banner.endDate}
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => onChangeBannerData(date, "endDate")}
                      minDate={banner.startDate ?? null}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="AddPrd-Formitem">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-light me-md-2 createBtn"
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  취소
                </button>
                <button
                  className="btn btn-primary me-md-2 createBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#Modal-04"
                  type="button"
                >
                  완료
                </button>
              </div>
            </div>
          </div>
          <div
            className="modal fade modal-register text-center"
            data-bs-backdrop="static"
            id="Modal-04"
            tabIndex="-1"
            aria-labelledby="Modal-04-Label"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <p className="modal-title">광고를 수정하시겠습니까?</p>
                </div>
                <div className="modal-body">광고 수정이 완료됩니다.</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-clear btn-modal-secondary"
                    data-bs-dismiss="modal"
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    className="btn btn-clear btn-modal-primary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      submit();
                    }}
                  >
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdModify;
