import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getImgUrl } from "./../function/common";
import { useState } from "react";
import { useEffect } from "react";

export default function Error() {
  return (
    <Container className="p-3 mt-5">
      <Row>
        <Col className="text-center">
          <Image
            className="w-50"
            src={getImgUrl("images/icon/image_errerpage_404.png")}
            fluid
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-center" xs={12}>
          <h1 className="display-1 fw-bold LabelTitle Label404">Error</h1>
          <p className="fs-3 LabelTitle Label404">
            이용에 불편을 드려 죄송합니다.
          </p>
          <p className="form-check-label">
            알수없는 오류가 발생 하였습니다.
            <br />
            뒤로가기 또는 홈으로 버튼을 눌러 다시 시도해주세요.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="d-grid">
          <Link to={"/"} className="btn btn-primary">
            홈으로
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
